import { isEqual } from 'lodash';

type IsValidFn<Type, Default> = (value: Type | Default | string) => boolean;
type ArchiveFn<Type, Default> = (value: Type | Default) => string;
type ExtractFn<Type> = (value: string) => Type | string;

export interface ParameterOptions<Type, Default> {
  name: string;
  short?: string;
  defaults?: Type | Default | null;
  isValid?: IsValidFn<Type, Default>;
  archive?: ArchiveFn<Type, Default>;
  extract?: ExtractFn<Type>;
}

interface ParameterStaticFns<Type, Default> {
  isValid: IsValidFn<Type, Default>;
  archive: ArchiveFn<Type, Default>;
  extract: ExtractFn<Type>;
}

export class Parameter<T, D> implements ParameterStaticFns<T, D> {
  static create = <Type, Default>(options: ParameterOptions<Type, Default>) => new Parameter(options);

  name = '';

  short = '';

  defaults: T | D | null = null;

  // eslint-disable-next-line
  isValid = value => Boolean(value);

  // eslint-disable-next-line
  archive = value => String(value);

  // eslint-disable-next-line
  extract = value => value;

  constructor (options: ParameterOptions<T, D>) {
    this.name = options.name;
    this.short = options.short ?? this.name;
    // eslint-disable-next-line no-undefined
    if (options.defaults !== undefined) {
      this.defaults = options.defaults;
    }
    if (typeof options.isValid === 'function') {
      this.isValid = options.isValid;
    }
    if (typeof options.archive === 'function') {
      this.archive = options.archive;
    }
    if (typeof options.extract === 'function') {
      this.extract = options.extract;
    }
  }

  to = (value: T) => {
    const def = this.defaults;
    const archived = this.archive(value);
    if (this.isValid(value) && !isEqual(archived, def)) {
      return archived;
    }
  };

  from = (value: string) : T | D | string | null => {
    const def = this.defaults;
    const extracted = this.extract(value);
    return this.isValid(extracted) ? extracted : def;
  };
}
