import { FilterFilled } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import React, { memo, useCallback, useMemo } from 'react';
import { isEmpty, isEqual, get, isNumber } from 'lodash/fp';
import { notification, Table, Tag, Tooltip, Typography } from 'antd';

import cn from './styles.less';
import { FILTER_KEYS, MainProps } from './types';
import { Changelog } from './changelog';
import { RangeForm } from './range-form';

import { FInventorySourcingItemFragment, SortDirection, useUpdateTargetQuantityMutation } from '~apollo/gql-types';

const { Paragraph } = Typography;

const ItemTypeColor = {
  Manual: 'blue',
  Auto: 'cyan',
};

export const Main = memo<MainProps>(({ loading, list, models, ctrl, updateCtrl, dictionaries }) => {
  const [updateTargetQuantity, { loading: disabled }] = useUpdateTargetQuantityMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['FilterInventorySourcing'],
    onError: (error) => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
    }
  });

  const handleUpdateTargetQuantity = useCallback(async (value, record) => {
    await updateTargetQuantity({
      variables: {
        input: {
          criteriaId: record?.id,
          targetQuantity: value
        }
      }
    });
  }, [updateTargetQuantity]);

  const getColumnSliderProps = useCallback((key) => ({
    filterDropdown: ({ confirm }) => {
      return (
        <RangeForm
          onSubmit={(values) => {
            updateCtrl({
              [key]: values,
            });
            confirm();
          }}
          initialValues={ctrl[key]}
          qaSelector={`range-form-${key}`}
        />
      );
    },
    filterIcon: () => {
      return (
        <FilterFilled
          type="search"
          style={{ color: (isNumber(ctrl[key]?.from) || isNumber(ctrl[key]?.to)) ? '#1890ff' : '#ccc' }}
        />
      );
    },
  }), [ctrl, updateCtrl]);

  const columns: ColumnsType<FInventorySourcingItemFragment> = useMemo(() => [
    {
      title: '',
      key: 'index',
      render: (item, record, index) => (
        <span data-qa-selector="table-cell-index">{ ((ctrl.page - 1) * ctrl.pageSize) + 1 + index }</span>
      ),
    },
    {
      title: <span data-qa-selector="table-head-type">Source</span>,
      key: 'index',
      sorter: true,
      dataIndex: 'type',
      render: (item) => (
        <span data-qa-selector="table-cell-type">
          <Tag color={ItemTypeColor[item]}>{ item }</Tag>
        </span>
      ),
    },
    {
      title: <span data-qa-selector="table-head-source-country" style={{ whiteSpace: 'nowrap' }}>Source country</span>,
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      filters: dictionaries?.countries.map(({ value }) => ({ text: value, value })),
      render: (item) => (
        <span data-qa-selector="table-cell-source-country">
          <Tooltip title={item?.label} color="#000">
            <Tag color="black">{ item?.value }</Tag>
          </Tooltip>
        </span>
      ),
      filteredValue: ctrl.country,
    },
    {
      title: <span data-qa-selector="table-head-target-country" style={{ whiteSpace: 'nowrap' }}>Target country</span>,
      dataIndex: 'targetCountry',
      key: 'targetCountry',
      sorter: true,
      filters: dictionaries?.countries.map(({ value }) => ({ text: value, value })),
      filteredValue: ctrl.targetCountry || null,
      render: (item) => (
        <span data-qa-selector="table-cell-target-country">
          <Tooltip title={item?.label} color="#000">
            <Tag color="black">{ item?.value }</Tag>
          </Tooltip>
        </span>
      ),
    },
    {
      title: <span data-qa-selector="table-head-make">Make</span>,
      dataIndex: 'make',
      key: 'makes',
      sorter: true,
      filters: dictionaries?.makes.map(({ value, label }) => ({ text: label, value })),
      filteredValue: ctrl.makes || null,
      render: (item) => (
        <span data-qa-selector="table-cell-make">{ item?.label }</span>
      ),
    },
    {
      title: <span data-qa-selector="table-head-model">Model</span>,
      dataIndex: 'model',
      key: 'models',
      sorter: true,
      render: (item) => (
        <span data-qa-selector="table-cell-model">{ item }</span>
      ),
      ...(isEmpty(ctrl.makes) ? {} : {
        filteredValue: ctrl.models || null,
        filters: models,
        filterMode: 'tree',
      })
    },
    {
      title: <span data-qa-selector="table-head-fuel">Fuel</span>,
      dataIndex: 'fuel',
      key: 'fuels',
      sorter: true,
      filters: dictionaries?.fuels.map(({ value, label }) => ({ text: label, value })),
      filteredValue: ctrl.fuels || null,
      render: (item) => (
        <span data-qa-selector="table-cell-fuel">
          <Tag color="geekblue">{ item?.label }</Tag>
        </span>
      ),
    },
    {
      title: <span data-qa-selector="table-head-age">Age (years)</span>,
      dataIndex: 'ageBucket',
      key: 'age',
      sorter: true,
      render: (item) => (
        <span data-qa-selector="table-cell-age">{ item?.label }</span>
      ),
      filters: dictionaries?.ageBuckets.map(({ value, label }) => ({ text: label, value })),
      filteredValue: ctrl.age || null,
    },
    {
      title: <span data-qa-selector="table-head-total">Search (total)</span>,
      dataIndex: 'searchTotal',
      key: 'total',
      sorter: true,
      render: (item) => (
        <span data-qa-selector="table-cell-total">{ item }</span>
      ),
      ...getColumnSliderProps(FILTER_KEYS.TOTAL),
    },
    {
      title: <span data-qa-selector="table-head-available">Available (QTY)</span>,
      dataIndex: 'availableQuantity',
      key: 'available',
      sorter: true,
      render: (item) => (
        <span data-qa-selector="table-cell-available">{ item }</span>
      ),
      ...getColumnSliderProps(FILTER_KEYS.AVAILABLE),
    },
    {
      title: <span data-qa-selector="table-head-unfulfilled">Unfulfilled (QTY)</span>,
      dataIndex: 'unfulfilledQuantity',
      key: 'unfulfilled',
      sorter: true,
      render: (item) => (
        <span data-qa-selector="table-cell-unfulfilled">{ item }</span>
      ),
      ...getColumnSliderProps(FILTER_KEYS.UNFULFILLED),
    },
    {
      title: <span data-qa-selector="table-head-target">Target (QTY)</span>,
      dataIndex: 'targetQuantity',
      key: 'target',
      sorter: true,
      render: (item, record) => (
        <div data-qa-selector="table-cell-target">
          <Paragraph
            disabled={disabled}
            style={{ margin: 0, left: 0 }}
            editable={{ onChange: value => handleUpdateTargetQuantity(value, record) }}
          >
            { String(item) }
          </Paragraph>
        </div>
      ),
      ...getColumnSliderProps(FILTER_KEYS.TARGET),
    }
  ], [
    models,
    ctrl.country,
    ctrl.targetCountry,
    ctrl.makes,
    ctrl.models,
    ctrl.fuels,
    ctrl.age,
    ctrl.page,
    ctrl.pageSize,
    dictionaries?.makes,
    dictionaries?.fuels,
    dictionaries?.countries,
    dictionaries?.ageBuckets,
    disabled,
    getColumnSliderProps,
    handleUpdateTargetQuantity
  ]);

  const onChange = useCallback((pagination, filters, sorter, extra) => {
    const { action } = extra;
    const { field, order } = sorter;
    if (action === 'filter') {
      updateCtrl({
        ...filters,
        models: isEqual(filters.makes, ctrl.makes) ? filters.models : []
      });
    } else if (action === 'sort' && order) {
      updateCtrl({
        page: 1,
        property: String(field),
        sortDirection: order === 'ascend' ? SortDirection.Asc : SortDirection.Desc
      });
    } else {
      updateCtrl({
        page: 1,
        property: null,
        sortDirection: SortDirection.Asc
      });
    }
  }, [ctrl.makes, updateCtrl]);
  return (
    <Table
      bordered
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={list}
      pagination={false}
      onChange={onChange}
      scroll={{ x: true }}
      className={cn.table}
      data-qa-selector="table-inventory-sourcing"
      rowClassName="table-inventory-sourcing-row"
      onRow={(columns, index) => ({
        className: `table-inventory-sourcing-row-${index}`,
      })}
      expandable={{ expandedRowRender: (data) => <Changelog key={data?.id} id={data?.id} /> }}
    />
  );
});
