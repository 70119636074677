import { get } from 'lodash/fp';
import React, { memo } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { Form, Input, Typography, InputNumber } from 'antd';

import cn from './styles.less';

import { FInputProps } from '~components/form/input.types';

const { Text } = Typography;

export const FInput = memo<FInputProps<FieldValues>>(
  ({
    wrapperCol,
    required,
    errors,
    label,
    controllerProps,
    format = (e) => e,
    parse = (e) => e,
    type,
    labelCol,
    qaSelector,
    ...restInputProps
  }) => {
    const { field } = useController(controllerProps);
    const error = get(controllerProps?.name)(errors);
    return (
      <Form.Item
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        help={error ? error?.message : null}
        validateStatus={error ? 'error' : null}
        data-qa-selector={`${qaSelector}-wrapper`}
        label={label ? <Text data-qa-selector={`${qaSelector}-label`}>{ `${label} ${required ? '*' : ''}` }</Text> : null}
      >
        <div className={cn.inputWrapper}>
          { type === 'number' ? (
            <InputNumber
              {...field}
              value={format(field.value)}
              data-qa-selector={`${qaSelector}-field`}
              onChange={(value) => field.onChange(parse(value))}
              {...restInputProps}
              decimalSeparator=","
            />
          ) : (
            <Input
              {...field}
              value={format(field.value)}
              data-qa-selector={`${qaSelector}-field`}
              onChange={(value) => field.onChange(parse(value))}
              {...restInputProps}
            />
          ) }
        </div>
      </Form.Item>
    );
  }
);
