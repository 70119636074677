import qs from 'qs';
import React, { ReactNode } from 'react';

import { Main } from './pages';
import { FILTER_KEYS } from './pages/inventory-sourcing/list/types';
import { Route } from './services/route/route';
import { InventorySourcing } from './pages/inventory-sourcing';
import { IRange, IType } from './pages/inventory-sourcing/list';
import { ANNOTATION, defineParameter, defineRoute } from './services/route';

const rootParams = [
  ANNOTATION.NAME({ name: 'language', short: 'language' })
];

const APP = defineRoute('', '', {
  params: rootParams
});

const ROOT = defineRoute('', ':language', {
  params: rootParams
});

export const INVENTORY_SOURCING = defineRoute(ROOT.ROUTE, 'inventory-sourcing', {
  params: rootParams,
  query: [
    ANNOTATION.PAGE({ defaults: 1 }),
    ANNOTATION.PAGE_SIZE({ defaults: 50 }),
    ANNOTATION.SORT_DIRECTION({}),
    ANNOTATION.SORT_FIELD({ defaults: null }),
    defineParameter<IType, IType>({
      short: 't', name: 'type', defaults: 'All'
    }),
    defineParameter<string, string>({
      short: 'rg', name: 'range', defaults: 'thirty-days'
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'tgc',
      name: 'targetCountry',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'sc',
      name: 'country',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'mk',
      name: 'makes',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'ml',
      name: 'models',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'fl',
      name: 'fuels',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<Array<string>, Array<string>>({
      short: 'ag',
      name: 'age',
      defaults: [],
      extract: value => value?.split(','),
      archive: value => value?.join(','),
    }),
    defineParameter<IRange, IRange>({
      short: 'tl',
      name: FILTER_KEYS.TOTAL,
      defaults: { from: null, to: null },
      extract: value => {
        const r = qs.parse(value);
        return ({
          from: r?.from ? Number(r.from) : null,
          to: r?.to ? Number(r.to) : null,
        });
      },
      archive: value => qs.stringify(value),
    }),
    defineParameter<IRange, IRange>({
      short: 'tg',
      name: FILTER_KEYS.TARGET,
      defaults: { from: null, to: null },
      extract: value => {
        const r = qs.parse(value);
        return ({
          from: r?.from ? Number(r.from) : null,
          to: r?.to ? Number(r.to) : null,
        });
      },
      archive: value => qs.stringify(value),
    }),
    defineParameter<IRange, IRange>({
      short: 'av',
      name: FILTER_KEYS.AVAILABLE,
      defaults: { from: null, to: null },
      extract: value => {
        const r = qs.parse(value);
        return ({
          from: r?.from ? Number(r.from) : null,
          to: r?.to ? Number(r.to) : null,
        });
      },
      archive: value => qs.stringify(value),
    }),
    defineParameter<IRange, IRange>({
      short: 'unf',
      name: FILTER_KEYS.UNFULFILLED,
      defaults: { from: null, to: null },
      extract: value => {
        const r = qs.parse(value);
        return ({
          from: r?.from ? Number(r.from) : null,
          to: r?.to ? Number(r.to) : null,
        });
      },
      archive: value => qs.stringify(value),
    }),
  ]
});

// PREPARE ROUTES
interface IRoute {
  route: Route
  link: string
  element: ReactNode
}

const routes: Array<IRoute> = [
  {
    route: APP,
    link: APP.LINK(),
    element: <Main />,
  },
  {
    route: INVENTORY_SOURCING,
    link: INVENTORY_SOURCING.LINK({ language: 'en' }),
    element: <InventorySourcing />,
  },
];

export default routes;
