import { ApolloProvider } from '@apollo/client';
import { ConfigProvider, notification } from 'antd';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import routes, { INVENTORY_SOURCING } from '../../routes';

import { ANTD_EXTERNAL_VERSION_LESS } from './constants';

import { client } from '~apollo/gql-client';

import './styles.less';

notification.config({
  top: 55,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

export const Root = () => {
  return (
    <div id="inventory-sourcing" data-qa-selector="inventory-sourcing">
      <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <Routes>
              { routes.map(({ route, element }) => (
                <Route key={route.ROUTE} path={route.ROUTE} element={element} />
              )) }
              <Route path="*" element={<Navigate to={INVENTORY_SOURCING.LINK()} />} />
            </Routes>
          </BrowserRouter>
        </ApolloProvider>
      </ConfigProvider>
    </div>
  );
};
