import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Col, Row, Typography } from 'antd';

import routes from '../routes';
import packageJson from '../../package.json';

const { Title, Paragraph, Text, Link: NavLink } = Typography;

export const Main = memo(() => (
  <Row justify="center">
    <Col span={20}>
      <Typography>
        <Title>{ packageJson.name }</Title>
        <Paragraph>
          Welcome to our MFE
        </Paragraph>
        <Alert message="BE expects auth token Bearer {token} pattern. We create this header from cookies. To make it work - go to develop, log in, copy the cookie t and paste it into your application for local development." type="warning" />
        <br/>
        <Text>Available routes:</Text>
        {
          routes.map(({ route, link }) => (
            <pre key={route.ROUTE}>
              <Link to={link}>
                <code>
                  { route.ROUTE }
                </code>
              </Link>
            </pre>
          ))
        }
        <Text>Helpful links:</Text>
        <br/>
        <NavLink href="https://wkdauto.atlassian.net/wiki/spaces/RET/pages/3650519276/How+To+test+your+BackOffice+MFE+custom+branch">How to test your BackOffice MFE custom branch</NavLink>
        <br/>
        <NavLink href="https://wkdauto.atlassian.net/wiki/spaces/RET/pages/3322871850/Deploy+to+production">Deploy to production</NavLink>
      </Typography>
    </Col>
  </Row>
));
