import { FILTER_KEYS } from './types';

import { FilterData } from './index';

import { SortDirection } from '~apollo/gql-types';

export const initialFilterData: FilterData = {
  // Pagination
  page: 1,
  pageSize: 50,
  property: null,
  sortDirection: SortDirection.Desc,
  // Filter
  range: 'thirty-days',
  type: 'All',
  country: [],
  targetCountry: [],
  makes: [],
  models: [],
  fuels: [],
  age: [],
  [FILTER_KEYS.TOTAL]: { from: null, to: null },
  [FILTER_KEYS.TARGET]: { from: null, to: null },
  [FILTER_KEYS.AVAILABLE]: { from: null, to: null },
  [FILTER_KEYS.UNFULFILLED]: { from: null, to: null },
};
