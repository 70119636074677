import { Button, Col, Row } from 'antd';
import React, { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { RangeFormProps } from './types';

import { FInput } from '~components/form/input';

export const RangeForm = memo<RangeFormProps>(
  ({ initialValues, onSubmit, qaSelector }) => {
    const {
      handleSubmit,
      control,
      reset,
      getValues,
    } = useForm({
      defaultValues: initialValues,
      mode: 'all',
    });

    useEffect(() => {
      reset(initialValues);
    }, [getValues, initialValues, reset]);

    const [from, to] = useWatch({
      control,
      name: ['from', 'to']
    });

    return (
      <form onSubmit={handleSubmit(onSubmit)} data-qa-selector={qaSelector}>
        <Row style={{ padding: 10, width: 320 }}>
          <Col span={12}>
            <FInput
              type="number"
              label="From"
              qaSelector="from"
              placeholder="From"
              labelCol={{ span: 8 }}
              style={{ width: '100%' }}
              wrapperCol={{ span: 16 }}
              controllerProps={{
                name: 'from',
                control,
              }}
            />
          </Col>
          <Col span={12}>
            <FInput
              type="number"
              label="To"
              qaSelector="to"
              placeholder="To"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ width: '100%' }}
              controllerProps={{
                name: 'to',
                control,
              }}
            />
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              type="link"
              size="small"
              data-qa-selector="reset"
              style={{ marginRight: 10 }}
              disabled={(from === null) && (to === null)}
              onClick={() => reset({ from: null, to: null })}
            >
              Reset
            </Button>
            <Button size="small" type="primary" htmlType="submit" data-qa-selector="submit">
              OK
            </Button>
          </Col>
        </Row>
      </form>
    );
  }
);
