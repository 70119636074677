import { get } from 'lodash/fp';
import React, { memo } from 'react';

import { InventorySourcingFormProps } from './form.types';

import { FSelect } from '~components/form/select';

// eslint-disable-next-line no-undef
export const InventorySourcingForm = memo<InventorySourcingFormProps>(
  ({ disabled, dictionaries, errors, models, loadingModels, control }) => {
    const modelOptions = get('0.models')(models) || [];
    return (
      <div>
        <FSelect
          required
          label="Makes"
          errors={errors}
          placeholder="Make"
          disabled={disabled}
          labelCol={{ span: 24 }}
          qaSelector="creation-make"
          options={dictionaries?.makes}
          controllerProps={{ name: 'make', control }}
          getOptionValue={(item) => item?.value ?? null}
        />
        <FSelect
          required
          label="Model"
          errors={errors}
          placeholder="Model"
          options={modelOptions}
          labelCol={{ span: 24 }}
          qaSelector="creation-model"
          disabled={loadingModels || disabled}
          controllerProps={{ name: 'model', control }}
          getOptionValue={(item) => item?.value ?? null}
        />
        <FSelect
          errors={errors}
          disabled={disabled}
          labelCol={{ span: 24 }}
          label="Source countries"
          placeholder="Source countries"
          options={dictionaries?.countries}
          qaSelector="creation-source-country"
          getOptionValue={(item) => item?.value ?? null}
          controllerProps={{ name: 'country', control }}
        />
        <FSelect
          errors={errors}
          disabled={disabled}
          labelCol={{ span: 24 }}
          label="Target countries"
          placeholder="Target countries"
          options={dictionaries?.countries}
          qaSelector="creation-target-country"
          getOptionValue={(item) => item?.value ?? null}
          controllerProps={{ name: 'targetCountry', control }}
        />
        <FSelect
          label="Fuel"
          errors={errors}
          placeholder="Fuel"
          disabled={disabled}
          labelCol={{ span: 24 }}
          qaSelector="creation-fuel"
          options={dictionaries?.fuels}
          controllerProps={{ name: 'fuel', control }}
          getOptionValue={(item) => item?.value ?? null}
        />
        <FSelect
          errors={errors}
          label="Age Bucket"
          disabled={disabled}
          labelCol={{ span: 24 }}
          placeholder="Age Bucket"
          qaSelector="creation-age-bucket"
          options={dictionaries?.ageBuckets}
          getOptionValue={(item) => item?.value ?? null}
          controllerProps={{ name: 'ageBucket', control }}
        />
      </div>
    );
  }
);
