import ReactHelmet from 'react-helmet';
import { isEmpty, get, isEqual } from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Pagination, Tabs, Button, Select, notification } from 'antd';

import * as ROUTES from '../../../routes';
import { showModal, CreateUnitModal } from '../create';

import { Main } from './main';
import cn from './styles.less';
import formToMeta from './formToMeta';
import { FILTER_KEYS } from './types';
import { initialFilterData } from './index.initial';

import { useDictionariesQuery, useModelsQuery, useFilterInventorySourcingQuery, SortDirection } from '~apollo/gql-types';

const { TabPane } = Tabs;

const pageSizeOptions = ['10', '50', '100', '200'];

export interface IRange {
  from: number | null
  to: number | null
}

export type IType = 'All' | 'Auto' | 'Manual'

export type FilterData = {
  // Pagination
  page: number
  pageSize: number
  property: string | null,
  sortDirection: SortDirection,
  // Filter
  range: string
  type: IType
  country: Array<string>
  targetCountry: Array<string>
  makes: Array<string>
  models: Array<string>
  fuels: Array<string>
  age: Array<string>
  [FILTER_KEYS.TOTAL]: IRange
  [FILTER_KEYS.TARGET]: IRange
  [FILTER_KEYS.AVAILABLE]: IRange
  [FILTER_KEYS.UNFULFILLED]: IRange
};

const rangeOptions = [
  { value: 'thirty-days', label: '30 days' },
  { value: 'fourteen-days', label: '14 days' },
  { value: 'seven-days', label: '7 days' },
  { value: 'three-days', label: '3 days' },
  { value: 'one-day', label: '1 day' },
];

export const List = () => {
  const location = useLocation();
  const { language } = useParams();
  const navigate = useNavigate();
  const initialCtrl = useMemo(() => ({
    ...initialFilterData,
    ...ROUTES.INVENTORY_SOURCING.QUERY(location.search)
  }), [location.search]);

  const [models, setModels] = useState([]);
  const [ctrl, setCtrl] = useState<FilterData>(initialCtrl);
  const updateCtrl = useCallback((data: Partial<FilterData>) => {
    const params = { ...ctrl, ...data };
    setCtrl(params);
    navigate(ROUTES.INVENTORY_SOURCING.LINK({ language }, params));
  }, [ctrl, language, navigate]);

  const { data } = useDictionariesQuery();

  useModelsQuery({
    skip: isEmpty(ctrl.makes),
    variables: {
      makesInputs: ctrl.makes
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
    },
    onCompleted: ({ models }) => {
      setModels(
        models.map(({ make, models }) => ({
          text: make.label,
          value: make.value,
          children: models.map(({ value, label }) => ({ text: label, value }))
        }))
      );
    }
  });

  const { data: inventories, loading: loadingInventories } = useFilterInventorySourcingQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: formToMeta()(ctrl),
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
    },
  });

  const onPageChange = useCallback((value, pageSize) => {
    updateCtrl({ page: pageSize !== ctrl.pageSize ? 1 : value, pageSize });
  }, [ctrl.pageSize, updateCtrl]);

  const resetFilter = useCallback(() => updateCtrl(initialFilterData), [updateCtrl]);
  const showCreateUnitModal = useCallback(() => showModal(), []);
  const updateRange = useCallback(range => updateCtrl({ range }), [updateCtrl]);

  return (
    <div style={{ padding: 20 }} className={cn.page}>
      <ReactHelmet title="BO - Inventory Sourcing" />
      <CreateUnitModal
        dictionaries={data?.dictionaries}
      />
      <Row gutter={20}>
        <Col span={21}>
          <h1 className={cn.title} data-qa-selector="title">
            Inventory Sourcing
          </h1>
        </Col>
        <Col span={3} className={cn.btn}>
          <Button
            block
            type="default"
            onClick={showCreateUnitModal}
            data-qa-selector="btn-create-unit"
          >
            Create Unit
          </Button>
        </Col>
      </Row>
      <Row gutter={20} className={cn.offsetBottom}>
        <Col offset={15} span={6}>
          <Select
            value={ctrl.range}
            onChange={updateRange}
            style={{ width: '100%' }}
          >
            { rangeOptions.map(({ value, label }) => (
              <Select.Option
                key={value}
                value={value}
                data-qa-selector="option"
                data-qa-selector-option-value={value}
                data-qa-selector-option-label={label}
              >
                { label }
              </Select.Option>
            )) }
          </Select>
        </Col>
        <Col span={3}>
          <Button
            block
            type="primary"
            onClick={resetFilter}
            data-qa-selector="btn-reset"
            disabled={isEqual(initialFilterData, ctrl)}
          >
            Reset filters
          </Button>
        </Col>
        <Col span={24}>
          <Tabs
            data-qa-selector="tabs"
            defaultActiveKey={ctrl.type}
            onChange={(tab: IType) => updateCtrl({ type: tab })}
          >
            <TabPane tab="All" key="All" data-qa-selector="tab-all"/>
            <TabPane tab="Auto" key="Auto" data-qa-selector="tab-auto"/>
            <TabPane tab="Manual" key="Manual" data-qa-selector="tab-manual"/>
          </Tabs>
        </Col>
      </Row>
      <Row gutter={20} className={cn.offsetBottom}>
        <Col span={8} className={cn.centering}>
          <h3 className={cn.subheading} data-qa-selector="total-results">{ `${inventories?.data?.totalEntityCount || 0} Total results` }</h3>
        </Col>
        <Col span={16} className={cn.pagination}>
          <Pagination
            current={ctrl.page}
            onChange={onPageChange}
            pageSize={ctrl.pageSize}
            data-qa-selector="pagination"
            disabled={loadingInventories}
            pageSizeOptions={pageSizeOptions}
            total={inventories?.data?.totalEntityCount}
          />
        </Col>
      </Row>
      <Row className={cn.offsetBottom}>
        <Col span={24}>
          <Main
            ctrl={ctrl}
            models={models}
            updateCtrl={updateCtrl}
            loading={loadingInventories}
            list={inventories?.data?.items}
            dictionaries={data?.dictionaries}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className={cn.pagination}>
          <Pagination
            current={ctrl.page}
            onChange={onPageChange}
            pageSize={ctrl.pageSize}
            data-qa-selector="pagination"
            disabled={loadingInventories}
            pageSizeOptions={pageSizeOptions}
            total={inventories?.data?.totalEntityCount}
          />
        </Col>
      </Row>
    </div>
  );
};
