/* eslint-disable no-unused-vars */
import { ColumnFilterItem } from 'antd/es/table/interface';
import { FieldValues } from 'react-hook-form';

import { FilterData } from './index';

import { FDictionariesFragment, FInventorySourcingItemFragment } from '~apollo/gql-types';

export enum FILTER_KEYS {
  TOTAL = 'total',
  TARGET = 'target',
  AVAILABLE = 'available',
  UNFULFILLED = 'unfulfilled',
}

export interface ExpandableProps {
  id: string,
}

export interface MainProps {
  loading: boolean,
  ctrl: FilterData,
  models: Array<ColumnFilterItem>,
  dictionaries?: FDictionariesFragment,
  list: Array<FInventorySourcingItemFragment>,
  updateCtrl: (data: Partial<FilterData>) => void,
}

export interface RangeFormProps {
  onSubmit: (values) => void,
  initialValues: FieldValues,
  qaSelector: string,
}
