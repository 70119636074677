import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

import typePolicies from './policies';

export function getCookieByName (name, context = document.cookie) {
  const value = `; ${context}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
}

const httpLink = createHttpLink({
  uri: `/${process.env.API_URL}`,
});

// NOTE Prepare common headers for all requests
const authLink = setContext(() => {
  // NOTE get the authentication access token from cookies if it exists
  const accessToken = getCookieByName('t');
  // NOTE return the headers to the context so httpLink can read them
  return {
    headers: {
      accept: 'application/json, text/plain, */*',
      'content-type': 'application/json;charset=UTF-8',
      authorization: `${process.env.SCHEMA_AUTH_TITLE} ${accessToken}`,
    },
  };
});

// NOTE Apollo client
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, onError(handleErrors), httpLink]),
  cache: new InMemoryCache({ typePolicies }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
  },
});

export function handleErrors ({ networkError, operation, forward }) {
  if (networkError?.statusCode === 401) {
    const accessToken = getCookieByName('t');
    operation.setContext({
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return forward(operation);
  }
}
