import { assign, flow, isEmpty, isNumber } from 'lodash/fp';

import { FILTER_KEYS } from './types';

import { FilterData } from './index';

import { FilterType } from '~utils/gql';
import { SortDirection } from '~apollo/gql-types';

export default (defaultValues: Partial<FilterData> = {}) =>
  flow(
    assign(defaultValues),
    ({
      // Pagination
      page: currentPage,
      pageSize,
      // Sort
      property,
      sortDirection,
      // Filter
      age,
      type,
      range,
      makes,
      fuels,
      models,
      country,
      targetCountry,
      ...ranges
    }) => {
      const page = Number(currentPage) - 1 || 0;
      const filters = [];
      const sorts = [];

      if (type && type !== 'All') {
        filters.push({
          property: 'type',
          operation: FilterType.EQUAL,
          value: type,
        });
      }

      if (!isEmpty(country)) {
        filters.push({
          property: 'country',
          operation: FilterType.IN,
          values: country,
        });
      }

      if (!isEmpty(targetCountry)) {
        filters.push({
          property: 'targetCountry',
          operation: FilterType.IN,
          values: targetCountry,
        });
      }

      if (!isEmpty(makes)) {
        filters.push({
          property: 'make',
          operation: FilterType.IN,
          values: makes,
        });
      }

      if (!isEmpty(models)) {
        filters.push({
          property: 'model',
          operation: FilterType.IN,
          values: models,
        });
      }

      if (!isEmpty(fuels)) {
        filters.push({
          property: 'fuel',
          operation: FilterType.IN,
          values: fuels,
        });
      }

      if (!isEmpty(age)) {
        filters.push({
          property: 'ageBucket',
          operation: FilterType.IN,
          values: age,
        });
      }

      if (!isEmpty(ranges[FILTER_KEYS.TOTAL])) {
        const range = ranges[FILTER_KEYS.TOTAL];
        if (isNumber(range.from) && isNumber(range.to)) {
          filters.push({
            property: 'searchTotal',
            operation: FilterType.BETWEEN,
            value: `${range.from},${range.to}`,
          });
        } else if (isNumber(range.to)) {
          filters.push({
            property: 'searchTotal',
            operation: FilterType.LESS_THAN_OR_EQUAL,
            value: range.to,
          });
        } else if (isNumber(range.from)) {
          filters.push({
            property: 'searchTotal',
            operation: FilterType.GREATER_THAN_OR_EQUAL,
            value: range.from,
          });
        }
      }

      if (!isEmpty(ranges[FILTER_KEYS.UNFULFILLED])) {
        const range = ranges[FILTER_KEYS.UNFULFILLED];
        if (isNumber(range.from) && isNumber(range.to)) {
          filters.push({
            property: 'unfulfilledQuantity',
            operation: FilterType.BETWEEN,
            value: `${range.from},${range.to}`,
          });
        } else if (isNumber(range.to)) {
          filters.push({
            property: 'unfulfilledQuantity',
            operation: FilterType.LESS_THAN_OR_EQUAL,
            value: range.to,
          });
        } else if (isNumber(range.from)) {
          filters.push({
            property: 'unfulfilledQuantity',
            operation: FilterType.GREATER_THAN_OR_EQUAL,
            value: range.from,
          });
        }
      }

      if (!isEmpty(ranges[FILTER_KEYS.AVAILABLE])) {
        const range = ranges[FILTER_KEYS.AVAILABLE];
        if (isNumber(range.from) && isNumber(range.to)) {
          filters.push({
            property: 'availableQuantity',
            operation: FilterType.BETWEEN,
            value: `${range.from},${range.to}`,
          });
        } else if (isNumber(range.to)) {
          filters.push({
            property: 'availableQuantity',
            operation: FilterType.LESS_THAN_OR_EQUAL,
            value: range.to,
          });
        } else if (isNumber(range.from)) {
          filters.push({
            property: 'availableQuantity',
            operation: FilterType.GREATER_THAN_OR_EQUAL,
            value: range.from,
          });
        }
      }

      if (!isEmpty(ranges[FILTER_KEYS.TARGET])) {
        const range = ranges[FILTER_KEYS.TARGET];
        if (isNumber(range.from) && isNumber(range.to)) {
          filters.push({
            property: 'targetQuantity',
            operation: FilterType.BETWEEN,
            value: `${range.from},${range.to}`,
          });
        } else if (isNumber(range.to)) {
          filters.push({
            property: 'targetQuantity',
            operation: FilterType.LESS_THAN_OR_EQUAL,
            value: range.to,
          });
        } else if (isNumber(range.from)) {
          filters.push({
            property: 'targetQuantity',
            operation: FilterType.GREATER_THAN_OR_EQUAL,
            value: range.from,
          });
        }
      }

      if (property && sortDirection) {
        sorts.push({
          property,
          sortDirection,
        });
      } else {
        sorts.push({ sortDirection: SortDirection.Desc, property: 'type' });
        sorts.push({ sortDirection: SortDirection.Asc, property: 'make' });
        sorts.push({ sortDirection: SortDirection.Asc, property: 'model' });
        sorts.push({ sortDirection: SortDirection.Desc, property: 'searchTotal' });
        sorts.push({ sortDirection: SortDirection.Asc, property: 'gear' });
        sorts.push({ sortDirection: SortDirection.Asc, property: 'fuel' });
      }

      return ({
        page,
        sorts,
        filters,
        pageSize,
        rangeType: range,
      });
    },
  );
