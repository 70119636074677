// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Cx7PfVpAe1NExpyUYqkp1Q\\=\\= {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/styles.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF","sourcesContent":[".inputWrapper {\n  display: flex;\n}\n\n\n@ant-prefix: antd-4-21-7;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "Cx7PfVpAe1NExpyUYqkp1Q=="
};
export default ___CSS_LOADER_EXPORT___;
