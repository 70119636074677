import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Long: any;
  UUID: any;
};

export type AggregatedInventoryInput = {
  filters?: InputMaybe<Array<Filter>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  rangeType?: InputMaybe<Scalars['String']>;
  sorts?: InputMaybe<Array<Sort>>;
};

export type AggregatedInventoryItem = {
  __typename?: 'AggregatedInventoryItem';
  ageBucket?: Maybe<OptionStringItem>;
  availableQuantity?: Maybe<Scalars['Int']>;
  country?: Maybe<OptionStringItem>;
  fuel?: Maybe<OptionNumberItem>;
  id: Scalars['UUID'];
  make?: Maybe<OptionStringItem>;
  model?: Maybe<Scalars['String']>;
  searchTotal?: Maybe<Scalars['Int']>;
  targetCountry?: Maybe<OptionStringItem>;
  targetQuantity?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unfulfilledQuantity?: Maybe<Scalars['Int']>;
};

export type AggregatedInventoryPage = {
  __typename?: 'AggregatedInventoryPage';
  items?: Maybe<Array<AggregatedInventoryItem>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount: Scalars['Long'];
  totalPageCount: Scalars['Int'];
};

export type ChangeLogInventory = {
  __typename?: 'ChangeLogInventory';
  action?: Maybe<Scalars['String']>;
  commentId?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<User>;
  createdOn?: Maybe<Scalars['DateTime']>;
  field?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Long']>;
  newValue?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['UUID']>;
  objectType?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['UUID']>;
  parentType?: Maybe<Scalars['String']>;
};

export type ChangeLogInventoryInput = {
  id: Scalars['UUID'];
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sorts?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type ChangeLogInventoryPage = {
  __typename?: 'ChangeLogInventoryPage';
  items?: Maybe<Array<ChangeLogInventory>>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalEntityCount: Scalars['Long'];
  totalPageCount: Scalars['Int'];
};

export type Filter = {
  operation: Scalars['String'];
  property: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type InventorySourcingConstants = {
  __typename?: 'InventorySourcingConstants';
  ageBuckets: Array<OptionStringItem>;
  countries: Array<OptionStringItem>;
  fuels: Array<OptionNumberItem>;
  makes: Array<OptionStringItem>;
  targetCountries: Array<OptionStringItem>;
};

export type ManualInventoryInput = {
  ageBucket?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  fuel?: InputMaybe<Scalars['Int']>;
  make: Scalars['String'];
  model: Scalars['String'];
  targetCountry?: InputMaybe<Scalars['String']>;
};

export type ModelItem = {
  __typename?: 'ModelItem';
  make: OptionStringItem;
  models: Array<OptionStringItem>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createManualInventory: Scalars['Boolean'];
  updateTargetQuantity: UpdatedInventoryTarget;
};


export type MutationCreateManualInventoryArgs = {
  input: ManualInventoryInput;
};


export type MutationUpdateTargetQuantityArgs = {
  input: UpdateTargetQuantityInput;
};

export type OptionNumberItem = {
  __typename?: 'OptionNumberItem';
  label: Scalars['String'];
  value: Scalars['Int'];
};

export type OptionStringItem = {
  __typename?: 'OptionStringItem';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  changeLogInventoryPage: ChangeLogInventoryPage;
  inventorySourcingConstants: InventorySourcingConstants;
  inventorySourcingPage: AggregatedInventoryPage;
  modelConstants: Array<ModelItem>;
};


export type QueryChangeLogInventoryPageArgs = {
  input: ChangeLogInventoryInput;
};


export type QueryInventorySourcingPageArgs = {
  input: AggregatedInventoryInput;
};


export type QueryModelConstantsArgs = {
  makesInputs: Array<Scalars['String']>;
};

export type Sort = {
  property: Scalars['String'];
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type UpdateTargetQuantityInput = {
  criteriaId: Scalars['UUID'];
  targetQuantity: Scalars['Int'];
};

export type UpdatedInventoryTarget = {
  __typename?: 'UpdatedInventoryTarget';
  targetQuantity: Scalars['Int'];
  unfulfilledQuantity: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['UUID'];
  lastName: Scalars['String'];
};

export type FModelFragment = { __typename?: 'ModelItem', models: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, make: { __typename?: 'OptionStringItem', label: string, value: string } };

export type FOptionStringItemFragment = { __typename?: 'OptionStringItem', label: string, value: string };

export type FOptionNumberItemFragment = { __typename?: 'OptionNumberItem', label: string, value: number };

export type FChangeLogPaginationFragment = { __typename?: 'ChangeLogInventoryPage', page: number, pageSize: number, totalPageCount: number, totalEntityCount: any, items?: Array<{ __typename?: 'ChangeLogInventory', action?: string | null, commentId?: number | null, createdOn?: any | null, field?: string | null, id?: any | null, newValue?: string | null, objectId?: any | null, objectType?: string | null, oldValue?: string | null, parentId?: any | null, parentType?: string | null, createdBy?: { __typename?: 'User', id: any, email: string, firstName: string, lastName: string } | null }> | null };

export type FChangeLogItemFragment = { __typename?: 'ChangeLogInventory', action?: string | null, commentId?: number | null, createdOn?: any | null, field?: string | null, id?: any | null, newValue?: string | null, objectId?: any | null, objectType?: string | null, oldValue?: string | null, parentId?: any | null, parentType?: string | null, createdBy?: { __typename?: 'User', id: any, email: string, firstName: string, lastName: string } | null };

export type FUserFragment = { __typename?: 'User', id: any, email: string, firstName: string, lastName: string };

export type FDictionariesFragment = { __typename?: 'InventorySourcingConstants', ageBuckets: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, countries: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, fuels: Array<{ __typename?: 'OptionNumberItem', label: string, value: number }>, makes: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, targetCountries: Array<{ __typename?: 'OptionStringItem', label: string, value: string }> };

export type FInventorySourcingPaginationFragment = { __typename?: 'AggregatedInventoryPage', page: number, pageSize: number, totalPageCount: number, totalEntityCount: any, items?: Array<{ __typename?: 'AggregatedInventoryItem', id: any, type?: string | null, model?: string | null, searchTotal?: number | null, targetQuantity?: number | null, availableQuantity?: number | null, unfulfilledQuantity?: number | null, ageBucket?: { __typename?: 'OptionStringItem', label: string, value: string } | null, country?: { __typename?: 'OptionStringItem', label: string, value: string } | null, fuel?: { __typename?: 'OptionNumberItem', label: string, value: number } | null, make?: { __typename?: 'OptionStringItem', label: string, value: string } | null, targetCountry?: { __typename?: 'OptionStringItem', label: string, value: string } | null }> | null };

export type FInventorySourcingItemFragment = { __typename?: 'AggregatedInventoryItem', id: any, type?: string | null, model?: string | null, searchTotal?: number | null, targetQuantity?: number | null, availableQuantity?: number | null, unfulfilledQuantity?: number | null, ageBucket?: { __typename?: 'OptionStringItem', label: string, value: string } | null, country?: { __typename?: 'OptionStringItem', label: string, value: string } | null, fuel?: { __typename?: 'OptionNumberItem', label: string, value: number } | null, make?: { __typename?: 'OptionStringItem', label: string, value: string } | null, targetCountry?: { __typename?: 'OptionStringItem', label: string, value: string } | null };

export type UpdateTargetQuantityMutationVariables = Exact<{
  input: UpdateTargetQuantityInput;
}>;


export type UpdateTargetQuantityMutation = { __typename?: 'Mutation', updateTargetQuantity: { __typename?: 'UpdatedInventoryTarget', targetQuantity: number, unfulfilledQuantity: number } };

export type CreateManualInventoryMutationVariables = Exact<{
  input: ManualInventoryInput;
}>;


export type CreateManualInventoryMutation = { __typename?: 'Mutation', createManualInventory: boolean };

export type DictionariesQueryVariables = Exact<{ [key: string]: never; }>;


export type DictionariesQuery = { __typename?: 'Query', dictionaries: { __typename?: 'InventorySourcingConstants', ageBuckets: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, countries: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, fuels: Array<{ __typename?: 'OptionNumberItem', label: string, value: number }>, makes: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, targetCountries: Array<{ __typename?: 'OptionStringItem', label: string, value: string }> } };

export type ModelsQueryVariables = Exact<{
  makesInputs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ModelsQuery = { __typename?: 'Query', models: Array<{ __typename?: 'ModelItem', models: Array<{ __typename?: 'OptionStringItem', label: string, value: string }>, make: { __typename?: 'OptionStringItem', label: string, value: string } }> };

export type FilterInventorySourcingQueryVariables = Exact<{
  input: AggregatedInventoryInput;
}>;


export type FilterInventorySourcingQuery = { __typename?: 'Query', data: { __typename?: 'AggregatedInventoryPage', page: number, pageSize: number, totalPageCount: number, totalEntityCount: any, items?: Array<{ __typename?: 'AggregatedInventoryItem', id: any, type?: string | null, model?: string | null, searchTotal?: number | null, targetQuantity?: number | null, availableQuantity?: number | null, unfulfilledQuantity?: number | null, ageBucket?: { __typename?: 'OptionStringItem', label: string, value: string } | null, country?: { __typename?: 'OptionStringItem', label: string, value: string } | null, fuel?: { __typename?: 'OptionNumberItem', label: string, value: number } | null, make?: { __typename?: 'OptionStringItem', label: string, value: string } | null, targetCountry?: { __typename?: 'OptionStringItem', label: string, value: string } | null }> | null } };

export type ChangeLogInventoryPageQueryVariables = Exact<{
  input: ChangeLogInventoryInput;
}>;


export type ChangeLogInventoryPageQuery = { __typename?: 'Query', data: { __typename?: 'ChangeLogInventoryPage', page: number, pageSize: number, totalPageCount: number, totalEntityCount: any, items?: Array<{ __typename?: 'ChangeLogInventory', action?: string | null, commentId?: number | null, createdOn?: any | null, field?: string | null, id?: any | null, newValue?: string | null, objectId?: any | null, objectType?: string | null, oldValue?: string | null, parentId?: any | null, parentType?: string | null, createdBy?: { __typename?: 'User', id: any, email: string, firstName: string, lastName: string } | null }> | null } };

export const FOptionStringItemFragmentDoc = gql`
    fragment FOptionStringItem on OptionStringItem {
  label
  value
}
    `;
export const FModelFragmentDoc = gql`
    fragment FModel on ModelItem {
  models {
    ...FOptionStringItem
  }
  make {
    ...FOptionStringItem
  }
}
    ${FOptionStringItemFragmentDoc}`;
export const FUserFragmentDoc = gql`
    fragment FUser on User {
  id
  email
  firstName
  lastName
}
    `;
export const FChangeLogItemFragmentDoc = gql`
    fragment FChangeLogItem on ChangeLogInventory {
  action
  commentId
  createdBy {
    ...FUser
  }
  createdOn
  field
  id
  newValue
  objectId
  objectType
  oldValue
  parentId
  parentType
}
    ${FUserFragmentDoc}`;
export const FChangeLogPaginationFragmentDoc = gql`
    fragment FChangeLogPagination on ChangeLogInventoryPage {
  page
  pageSize
  totalPageCount
  totalEntityCount
  items {
    ...FChangeLogItem
  }
}
    ${FChangeLogItemFragmentDoc}`;
export const FOptionNumberItemFragmentDoc = gql`
    fragment FOptionNumberItem on OptionNumberItem {
  label
  value
}
    `;
export const FDictionariesFragmentDoc = gql`
    fragment FDictionaries on InventorySourcingConstants {
  ageBuckets {
    ...FOptionStringItem
  }
  countries {
    ...FOptionStringItem
  }
  fuels {
    ...FOptionNumberItem
  }
  makes {
    ...FOptionStringItem
  }
  targetCountries {
    ...FOptionStringItem
  }
}
    ${FOptionStringItemFragmentDoc}
${FOptionNumberItemFragmentDoc}`;
export const FInventorySourcingItemFragmentDoc = gql`
    fragment FInventorySourcingItem on AggregatedInventoryItem {
  id
  type
  model
  searchTotal
  targetQuantity
  availableQuantity
  unfulfilledQuantity
  ageBucket {
    ...FOptionStringItem
  }
  country {
    ...FOptionStringItem
  }
  fuel {
    ...FOptionNumberItem
  }
  make {
    ...FOptionStringItem
  }
  targetCountry {
    ...FOptionStringItem
  }
}
    ${FOptionStringItemFragmentDoc}
${FOptionNumberItemFragmentDoc}`;
export const FInventorySourcingPaginationFragmentDoc = gql`
    fragment FInventorySourcingPagination on AggregatedInventoryPage {
  page
  pageSize
  totalPageCount
  totalEntityCount
  items {
    ...FInventorySourcingItem
  }
}
    ${FInventorySourcingItemFragmentDoc}`;
export const UpdateTargetQuantityDocument = gql`
    mutation UpdateTargetQuantity($input: UpdateTargetQuantityInput!) {
  updateTargetQuantity(input: $input) {
    targetQuantity
    unfulfilledQuantity
  }
}
    `;
export type UpdateTargetQuantityMutationFn = Apollo.MutationFunction<UpdateTargetQuantityMutation, UpdateTargetQuantityMutationVariables>;

/**
 * __useUpdateTargetQuantityMutation__
 *
 * To run a mutation, you first call `useUpdateTargetQuantityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTargetQuantityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTargetQuantityMutation, { data, loading, error }] = useUpdateTargetQuantityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTargetQuantityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTargetQuantityMutation, UpdateTargetQuantityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTargetQuantityMutation, UpdateTargetQuantityMutationVariables>(UpdateTargetQuantityDocument, options);
      }
export type UpdateTargetQuantityMutationHookResult = ReturnType<typeof useUpdateTargetQuantityMutation>;
export type UpdateTargetQuantityMutationResult = Apollo.MutationResult<UpdateTargetQuantityMutation>;
export type UpdateTargetQuantityMutationOptions = Apollo.BaseMutationOptions<UpdateTargetQuantityMutation, UpdateTargetQuantityMutationVariables>;
export const CreateManualInventoryDocument = gql`
    mutation CreateManualInventory($input: ManualInventoryInput!) {
  createManualInventory(input: $input)
}
    `;
export type CreateManualInventoryMutationFn = Apollo.MutationFunction<CreateManualInventoryMutation, CreateManualInventoryMutationVariables>;

/**
 * __useCreateManualInventoryMutation__
 *
 * To run a mutation, you first call `useCreateManualInventoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualInventoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualInventoryMutation, { data, loading, error }] = useCreateManualInventoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateManualInventoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateManualInventoryMutation, CreateManualInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManualInventoryMutation, CreateManualInventoryMutationVariables>(CreateManualInventoryDocument, options);
      }
export type CreateManualInventoryMutationHookResult = ReturnType<typeof useCreateManualInventoryMutation>;
export type CreateManualInventoryMutationResult = Apollo.MutationResult<CreateManualInventoryMutation>;
export type CreateManualInventoryMutationOptions = Apollo.BaseMutationOptions<CreateManualInventoryMutation, CreateManualInventoryMutationVariables>;
export const DictionariesDocument = gql`
    query Dictionaries {
  dictionaries: inventorySourcingConstants {
    ...FDictionaries
  }
}
    ${FDictionariesFragmentDoc}`;

/**
 * __useDictionariesQuery__
 *
 * To run a query within a React component, call `useDictionariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDictionariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDictionariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDictionariesQuery(baseOptions?: Apollo.QueryHookOptions<DictionariesQuery, DictionariesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DictionariesQuery, DictionariesQueryVariables>(DictionariesDocument, options);
      }
export function useDictionariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DictionariesQuery, DictionariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DictionariesQuery, DictionariesQueryVariables>(DictionariesDocument, options);
        }
export type DictionariesQueryHookResult = ReturnType<typeof useDictionariesQuery>;
export type DictionariesLazyQueryHookResult = ReturnType<typeof useDictionariesLazyQuery>;
export type DictionariesQueryResult = Apollo.QueryResult<DictionariesQuery, DictionariesQueryVariables>;
export const ModelsDocument = gql`
    query Models($makesInputs: [String!]!) {
  models: modelConstants(makesInputs: $makesInputs) {
    ...FModel
  }
}
    ${FModelFragmentDoc}`;

/**
 * __useModelsQuery__
 *
 * To run a query within a React component, call `useModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelsQuery({
 *   variables: {
 *      makesInputs: // value for 'makesInputs'
 *   },
 * });
 */
export function useModelsQuery(baseOptions: Apollo.QueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
      }
export function useModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, options);
        }
export type ModelsQueryHookResult = ReturnType<typeof useModelsQuery>;
export type ModelsLazyQueryHookResult = ReturnType<typeof useModelsLazyQuery>;
export type ModelsQueryResult = Apollo.QueryResult<ModelsQuery, ModelsQueryVariables>;
export const FilterInventorySourcingDocument = gql`
    query FilterInventorySourcing($input: AggregatedInventoryInput!) {
  data: inventorySourcingPage(input: $input) {
    ...FInventorySourcingPagination
  }
}
    ${FInventorySourcingPaginationFragmentDoc}`;

/**
 * __useFilterInventorySourcingQuery__
 *
 * To run a query within a React component, call `useFilterInventorySourcingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterInventorySourcingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterInventorySourcingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFilterInventorySourcingQuery(baseOptions: Apollo.QueryHookOptions<FilterInventorySourcingQuery, FilterInventorySourcingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterInventorySourcingQuery, FilterInventorySourcingQueryVariables>(FilterInventorySourcingDocument, options);
      }
export function useFilterInventorySourcingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterInventorySourcingQuery, FilterInventorySourcingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterInventorySourcingQuery, FilterInventorySourcingQueryVariables>(FilterInventorySourcingDocument, options);
        }
export type FilterInventorySourcingQueryHookResult = ReturnType<typeof useFilterInventorySourcingQuery>;
export type FilterInventorySourcingLazyQueryHookResult = ReturnType<typeof useFilterInventorySourcingLazyQuery>;
export type FilterInventorySourcingQueryResult = Apollo.QueryResult<FilterInventorySourcingQuery, FilterInventorySourcingQueryVariables>;
export const ChangeLogInventoryPageDocument = gql`
    query ChangeLogInventoryPage($input: ChangeLogInventoryInput!) {
  data: changeLogInventoryPage(input: $input) {
    ...FChangeLogPagination
  }
}
    ${FChangeLogPaginationFragmentDoc}`;

/**
 * __useChangeLogInventoryPageQuery__
 *
 * To run a query within a React component, call `useChangeLogInventoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeLogInventoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeLogInventoryPageQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeLogInventoryPageQuery(baseOptions: Apollo.QueryHookOptions<ChangeLogInventoryPageQuery, ChangeLogInventoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChangeLogInventoryPageQuery, ChangeLogInventoryPageQueryVariables>(ChangeLogInventoryPageDocument, options);
      }
export function useChangeLogInventoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChangeLogInventoryPageQuery, ChangeLogInventoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChangeLogInventoryPageQuery, ChangeLogInventoryPageQueryVariables>(ChangeLogInventoryPageDocument, options);
        }
export type ChangeLogInventoryPageQueryHookResult = ReturnType<typeof useChangeLogInventoryPageQuery>;
export type ChangeLogInventoryPageLazyQueryHookResult = ReturnType<typeof useChangeLogInventoryPageLazyQuery>;
export type ChangeLogInventoryPageQueryResult = Apollo.QueryResult<ChangeLogInventoryPageQuery, ChangeLogInventoryPageQueryVariables>;