import * as z from 'zod';
import { get } from 'lodash/fp';
import { Modal, notification } from 'antd';
import React, { memo, useCallback } from 'react';
import { useForm, useWatch, DeepPartial } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { makeVar, useReactiveVar } from '@apollo/client';

import { InventorySourcingForm } from './form';
import { CreateUnitModalProps } from './modal.types';

import { ManualInventoryInput, useCreateManualInventoryMutation, useModelsQuery } from '~apollo/gql-types';

export const manageCreateUnitModal = makeVar({ visible: false });

export const hideModal = () => manageCreateUnitModal({ visible: false });
export const showModal = () => manageCreateUnitModal({ visible: true });

export const schema: z.ZodSchema<DeepPartial<ManualInventoryInput>> = z.object({
  make: z.string({
    required_error: 'Field is required',
    invalid_type_error: 'Field is required',
  })
    .min(1, { message: 'Field is required' }),
  model: z.string({
    required_error: 'Field is required',
    invalid_type_error: 'Field is required',
  })
    .min(1, { message: 'Field is required' }),
  fuel: z.number().nullish(),
  country: z.string().nullish(),
  targetCountry: z.string().nullish(),
  ageBucket: z.string().nullish(),
});

const initial = {
  make: null,
  model: null,
  fuel: null,
  country: null,
  ageBucket: null,
  targetCountry: null,
};

export const CreateUnitModal = memo<CreateUnitModalProps>(({ dictionaries }) => {
  const { visible } = useReactiveVar(manageCreateUnitModal);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isSubmitted, isSubmitting },
  } = useForm<ManualInventoryInput>({
    mode: 'all',
    resolver: zodResolver(schema),
    defaultValues: initial
  });

  const make = useWatch({
    control,
    name: 'make'
  });

  const { data: models, loading: loadingModels } = useModelsQuery({
    skip: !make,
    variables: {
      makesInputs: [make]
    },
    onCompleted: () => {
      setValue('model', null);
    },
    onError: error => {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
    },
  });

  const [create] = useCreateManualInventoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: ['FilterInventorySourcing'],
  });

  const onSubmit = useCallback(async (values) => {
    try {
      await create({
        variables: {
          input: values
        }
      });
      hideModal();
      reset(initial);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: get('0.message')(error?.graphQLErrors),
      });
    }
  }, [create, reset]);

  return (
    <Modal
      centered
      width={600}
      zIndex={1050}
      visible={visible}
      title="Create Unit"
      onCancel={() => {
        hideModal();
        reset(initial);
      }}
      wrapProps={{
        'data-qa-selector': 'creation-modal'
      }}
      onOk={handleSubmit(onSubmit)}
      okButtonProps={{ loading: isSubmitting }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <InventorySourcingForm
          errors={errors}
          control={control}
          disabled={isSubmitting}
          models={models?.models}
          isSubmitted={isSubmitted}
          dictionaries={dictionaries}
          loadingModels={loadingModels}
        />
      </form>
    </Modal>
  );
});
