import { format } from 'date-fns';
import { Table, Tag } from 'antd';
import React, { memo, useCallback, useMemo, useState } from 'react';

import cn from './styles.less';
import { ExpandableProps } from './types';

import { SortDirection, useChangeLogInventoryPageQuery } from '~apollo/gql-types';

export type FilterData = {
  page: number
  pageSize: number
  property: string | null,
  totalEntityCount: number
  sortDirection: SortDirection,
}

const initial: FilterData = {
  page: 0,
  totalEntityCount: 0,
  pageSize: 10,
  property: 'createdOn',
  sortDirection: SortDirection.Desc,
};

export const Changelog = memo<ExpandableProps>(({ id }) => {

  const [ctrl, setCtrl] = useState<FilterData>(initial);
  const updateCtrl = useCallback(
    (data: Partial<FilterData>) => setCtrl({ ...ctrl, ...data }),
    [ctrl]
  );

  const { data, loading } = useChangeLogInventoryPageQuery({
    variables: {
      input: {
        id,
        page: ctrl.page,
        pageSize: ctrl.pageSize,
        sorts: [
          { property: ctrl.property, sortDirection: SortDirection.Desc }
        ]
      }
    },
    onCompleted: ({ data }) => {
      updateCtrl({ totalEntityCount: data?.totalEntityCount });
    },
  });

  // const onPageChange = useCallback((value, pageSize) => {
  //   updateCtrl({ page: value });
  // }, [updateCtrl]);

  const columns = useMemo(() => [
    {
      title: <strong data-qa-selector="table-changelogs-head-changelogs">Changelogs</strong>,
      key: 'createdOn',
      dataIndex: 'createdOn',
      render: (createdOn, record) => {
        return (
          <span data-qa-selector="table-changelogs-cell-changelogs">
            { format(new Date(createdOn), 'dd/MM/yyyy HH:mm') }
            <br/>
            { record.createdBy && <Tag color="#222">{ record.createdBy?.firstName } { record.createdBy?.lastName }</Tag> }
            <br/>
            { record.createdBy && <Tag color="#222">{ record.createdBy?.email }</Tag> }
          </span>
        );
      },
    },
    {
      title: <span data-qa-selector="table-changelogs-head-action">Action</span>,
      key: 'action',
      dataIndex: 'action',
      render: (action) => {
        return (
          <span data-qa-selector="table-changelogs-cell-action">
            <Tag color="green">{ action }</Tag>
          </span>
        );
      },
    },
    {
      title: <span data-qa-selector="table-changelogs-head-field">Field</span>,
      key: 'field',
      dataIndex: 'field',
      render: (field) => {
        return (
          <span data-qa-selector="table-changelogs-cell-field">
            { field ? `Field: ${field}` : '-' }
          </span>
        );
      },
    },
    {
      title: <span data-qa-selector="table-changelogs-head-history">History</span>,
      key: 'action',
      dataIndex: 'action',
      render: (action, record) => {
        return (
          <span data-qa-selector="table-changelogs-cell-history">
            <Tag color="#108ee9"> { record.oldValue } →{ ' ' }
              { action === 'insert' ? 'Created' : record.newValue }
            </Tag>
          </span>
        );
      },
    },
  ], []);

  return (
    <div data-qa-selector="changelogs">
      <Table
        bordered
        rowKey="id"
        loading={loading}
        columns={columns}
        scroll={{ x: true }}
        className={cn.table}
        dataSource={data?.data?.items}
        data-qa-selector="table-inventory-sourcing-changelogs"
        rowClassName="table-inventory-sourcing-changelogs-row"
        pagination={false}
        // pagination={{
        //   disabled: loading,
        //   current: ctrl.page+1,
        //   onChange: onPageChange,
        //   pageSize: ctrl.pageSize,
        //   total: ctrl.totalEntityCount,
        //   style: { marginRight: 20 },
        // }}
        onRow={(columns, index) => ({
          className: `table-inventory-sourcing-changelogs-row-${index}`,
        })}
      />
    </div>
  );
});
